import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import Landing from './components/Landing';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import CreateProfile from './components/CreateProfile';
import ManageProfiles from './components/ManageProfiles';
import CheckSignature from './components/CheckSignature';
import CheckDocument from './components/CheckDocument';
import PrivateRoute from './PrivateRoute';
import Layout from './Layout';
import NotFound from './components/NotFound';

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
        <Route path="/" element={<Navigate to="/welcome" />} />
          <Route path="/welcome" element={<Landing />} />
          <Route path="/Signup" element={<Signup />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>}>
            <Route path="create-profile" element={<CreateProfile />} />
            <Route path="manage-profiles" element={<ManageProfiles />} />
            <Route path="check-signature" element={<CheckSignature />} />
            <Route path="check-document" element={<CheckDocument />} />
          </Route>
          <Route path="/api/*" element={<div>API Routes Active</div>} /> {/* Placeholder for API routes */}
          <Route path="*" element={<NotFound />} /> {/* 404 Route */}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
