// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --background-light: #F7F9FC;
  --background-dark: #2E2E2E;
  --text-light: #333;
  --text-dark: #F7F9FC;
  --secondary-text-light: #666;
  --secondary-text-dark: #B0B0B0;
  --primary-button-light: #007BFF;
  --primary-button-dark: #0056b3;
  --button-text: #FFF;
}

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.light-mode {
  background-color: var(--background-light);
  color: var(--text-light);
}

.dark-mode {
  background-color: var(--background-dark);
  color: var(--text-dark);
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden; /* Prevent any overflow on the body */
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,0BAA0B;EAC1B,kBAAkB;EAClB,oBAAoB;EACpB,4BAA4B;EAC5B,8BAA8B;EAC9B,+BAA+B;EAC/B,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,yCAAyC;EACzC,wBAAwB;AAC1B;;AAEA;EACE,wCAAwC;EACxC,uBAAuB;AACzB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,YAAY;EACZ,gBAAgB,EAAE,qCAAqC;AACzD","sourcesContent":[":root {\r\n  --background-light: #F7F9FC;\r\n  --background-dark: #2E2E2E;\r\n  --text-light: #333;\r\n  --text-dark: #F7F9FC;\r\n  --secondary-text-light: #666;\r\n  --secondary-text-dark: #B0B0B0;\r\n  --primary-button-light: #007BFF;\r\n  --primary-button-dark: #0056b3;\r\n  --button-text: #FFF;\r\n}\r\n\r\nbody {\r\n  margin: 0;\r\n  font-family: 'Arial', sans-serif;\r\n}\r\n\r\n.light-mode {\r\n  background-color: var(--background-light);\r\n  color: var(--text-light);\r\n}\r\n\r\n.dark-mode {\r\n  background-color: var(--background-dark);\r\n  color: var(--text-dark);\r\n}\r\n\r\nhtml, body {\r\n  margin: 0;\r\n  padding: 0;\r\n  height: 100%;\r\n  overflow: hidden; /* Prevent any overflow on the body */\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
