import React from 'react';
import { Box, Button, Text, useColorModeValue, VStack, Icon } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { MdDataUsage, MdDocumentScanner, MdCheckCircle } from 'react-icons/md';

const Landing = () => {
  // Define gradients based on color mode
  const gradientLight = "linear(to-br, blue.400, green.400)";
  const gradientDark = "linear(to-br, teal.600, blue.600)";

  return (
    <Box
      textAlign="center"
      p="0" // Removed padding to prevent extra space
      bg={useColorModeValue('gray.100', 'gray.900')}
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center" // Center vertically
      borderRadius="20px" // Add rounded corners to the container
      boxShadow="xl" // Add a shadow for depth
      position="relative" // Allow absolute positioning of child elements
      overflow="hidden" // Prevent scrollbars
    >
      {/* Gradient Background */}
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        bgGradient={useColorModeValue(gradientLight, gradientDark)} // Use gradient based on color mode
        borderRadius="20px" // Match the border radius of the main container
        zIndex="0" // Place behind all other content
      />

      <Text fontSize="4xl" mb="6" fontWeight="bold" color={useColorModeValue('gray.800', 'white')} zIndex="1">
        DocuSafe - Signatures Made Safe with AI
      </Text>
      <VStack spacing={4} zIndex="1">
        <Link to="/Signup">
          <Button 
            colorScheme="blue" 
            size="lg" 
            borderRadius="full" // Make button edges rounded
            _hover={{ bg: 'blue.600', transform: 'scale(1.05)' }} // Hover effect
            transition="transform 0.2s ease" // Smooth transition
          >
            Get Started
          </Button>
        </Link>
        <Link to="/Login">
          <Button 
            colorScheme="teal" 
            size="lg" 
            borderRadius="full" // Make button edges rounded
            _hover={{ bg: 'teal.600', transform: 'scale(1.05)' }} // Hover effect
            transition="transform 0.2s ease" // Smooth transition
          >
            Login
          </Button>
        </Link>
      </VStack>
      <br></br>

      {/* Decorative Icons related to Machine Learning */}
      <Box 
        display="flex" 
        justifyContent="center" 
        position="relative"
        width="100%"
        height="200px" // Fixed height for the icon section
        zIndex="1" // Ensure icons appear above the gradient
      >
        {/* Decorative Shapes */}
        <Box position="absolute" borderRadius="full" bg="rgba(255, 255, 255, 0.3)" width="200px" height="200px" top="-50px" left="-50px" />
        <Box position="absolute" borderRadius="full" bg="rgba(255, 255, 255, 0.3)" width="150px" height="150px" bottom="-50px" right="-50px" />

        {/* Icons */}
        <Icon as={MdDocumentScanner} boxSize={10} color="white" mr={4} />
        <Icon as={MdDataUsage} boxSize={10} color="white" mr={4} />
        <Icon as={MdCheckCircle} boxSize={10} color="white" />
      </Box>
    </Box>
  );
};

export default Landing;
