import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { auth } from "../firebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const toast = useToast();
  const navigate = useNavigate();

  const handleSignup = async (event) => {
    event.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const idToken = await userCredential.user.getIdToken();

      const response = await fetch('/api/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          fullName: name,
          email: email,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }

      // Handle successful signup
      toast({
        title: "Account Created",
        description: "You can now log in!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      navigate("/login");
    } catch (error) {
      toast({
        title: "Signup Failed",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      p="6"
      borderRadius="lg"
      bg={useColorModeValue('gray.100', 'gray.900')}
      boxShadow="lg"
      color={useColorModeValue("black", "white")}
      minHeight="100vh"
    >
      <Text fontSize="2xl" mb="6">
        Sign Up
      </Text>
      <form onSubmit={handleSignup}>
        <FormControl mb="4" isRequired>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            bg={useColorModeValue("gray.100", "gray.700")}
          />
        </FormControl>
        <FormControl mb="4" isRequired>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            bg={useColorModeValue("gray.100", "gray.700")}
          />
        </FormControl>
        <FormControl mb="4" isRequired>
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            bg={useColorModeValue("gray.100", "gray.700")}
          />
        </FormControl>
        <Button type="submit" colorScheme="blue" width="full">
          Sign Up
        </Button>
      </form>
      <Button
        mt="4"
        onClick={() => navigate("/login")}
        colorScheme="teal"
        width="full"
      >
        Already have an account? Login
      </Button>
    </Box>
  );
};

export default Signup;
