import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Text,
  useToast,
  useColorModeValue,
  Progress,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";

const CheckSignature = () => {
  const [file, setFile] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState("");
  const [profiles, setProfiles] = useState([]);
  const [similarityScore, setSimilarityScore] = useState(null);
  const [maxSimilarityScore, setMaxSimilarityScore] = useState(null);
  const toast = useToast();
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const textColor = useColorModeValue("black", "white");

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        console.log("[CheckSignature] Fetching profiles");
        const token = localStorage.getItem("token");
        console.log("[CheckSignature] Token retrieved:", !!token);

        if (!token) {
          console.error("[CheckSignature] No authentication token");
          toast({
            title: "Authentication Error",
            description: "No authentication token found.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        const response = await fetch("/api/profiles", {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        });

        console.log("[CheckSignature] Fetch response status:", response.status);

        if (response.ok) {
          const data = await response.json();
          console.log("[CheckSignature] Profiles fetched:", data);
          
          setProfiles(data);
          
          // Ensure selectedProfile is a string value
          if (data.length > 0) {
            const firstProfile = data[0];
            console.log("[CheckSignature] Setting first profile:", firstProfile);
            setSelectedProfile(String(firstProfile));
          }
        } else {
          const errorText = await response.text();
          console.error("[CheckSignature] Profile fetch error:", errorText);
          
          toast({
            title: "Error",
            description: "Failed to load profiles.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("[CheckSignature] Profile fetch exception:", error);
        toast({
          title: "Network Error",
          description: "Unable to fetch profiles.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchProfiles();
  }, [toast]);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file && file.type.startsWith("image/")) {
      setFile(file);
    } else {
      toast({
        title: "Invalid File Type",
        description: "Please upload an image file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log("[CheckSignature] Submit attempt", { 
      profileSelected: selectedProfile, 
      filePresent: !!file 
    });

    if (!file || !selectedProfile) {
      console.warn("[CheckSignature] Missing file or profile");
      toast({
        title: "Submission Error",
        description: "Please select a profile and upload a signature image.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("profile", selectedProfile);
    formData.append("files", file);

    try {
      const token = localStorage.getItem("token");
      console.log("[CheckSignature] Submission token present:", !!token);

      const response = await fetch("/api/check_signature", {
        method: "POST",
        body: formData,
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });

      console.log("[CheckSignature] Response status:", response.status);
      
      const data = await response.json();
      console.log("[CheckSignature] Response data:", data);

      if (response.ok) {
        console.log("[CheckSignature] Signature check successful");
        setSimilarityScore(data[0].similarity_score);
        setMaxSimilarityScore(data[0].max_similarity_score);
      } else {
        console.error("[CheckSignature] Signature check failed:", data);
        throw new Error(data.message || "Failed to check signature.");
      }
    } catch (error) {
      console.error("[CheckSignature] Submission error:", error);
      toast({
        title: "Signature Check Error",
        description: error.message || "An error occurred checking the signature.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      console.log("[CheckSignature] Submission process completed");
      setFile(null);
    }
  };

  const renderScoreScale = () => {
    if (similarityScore === null && maxSimilarityScore === null) return null;

    let color = "red.400";
    let statusText = "Fraudulent";
    let scoreTextColor = "red.500";

    const effectiveScore = maxSimilarityScore > 99.5 ? maxSimilarityScore : similarityScore;

    if (effectiveScore > 99.5) {
      color = "red.500";
      statusText = "Likely Direct Copy (Fraudulent)";
    } else if (effectiveScore >= 92) {
      color = "green.400";
      statusText = "High Similarity (Likely Authentic)";
      scoreTextColor = "green.500";
    } else if (effectiveScore >= 80) {
      color = "yellow.400";
      statusText = "Moderate Similarity (Possibly Fraudulent)";
      scoreTextColor = "yellow.500";
    }

    return (
      <Box textAlign="center" mt="4">
        <Text fontWeight="bold" fontSize="xl" color={scoreTextColor}>
          Similarity Score: {effectiveScore.toFixed(2)}%
        </Text>
        <Box position="relative" mt="2">
          <Progress
            value={effectiveScore}
            size="lg"
            colorScheme={color === "red.500" ? "red" : color === "green.400" ? "green" : "yellow"}
            height="30px"
          />
        </Box>
        <Text mt="2" fontWeight="bold" color={scoreTextColor}>
          {statusText}
        </Text>
        <br />
        <Box mt="4" fontSize="sm">
          <Text color="red.500">99.6% - 100%: Likely Direct Copy (Fraudulent)</Text>
          <Text color="green.500">92% - 99.5%: High Similarity (Likely Authentic)</Text>
          <Text color="yellow.500">80% - 89.9%: Moderate Similarity (Possibly Fraudulent)</Text>
          <Text color="red.500">Below 80%: Low Similarity (Likely Fraudulent)</Text>
        </Box>
      </Box>
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [],
    },
  });

  return (
    <Box p="4" borderRadius="lg" bg={bgColor} boxShadow="md" color={textColor} maxHeight="90vh" overflowY="auto">
      <Text fontSize="xl" mb="4">Check Signature</Text>
      <form onSubmit={handleSubmit}>
        <FormControl mb="4">
          <FormLabel>Select Profile</FormLabel>
          <Select
            placeholder="Select profile"
            value={selectedProfile}
            onChange={(e) => {
              console.log("[CheckSignature] Profile selection changed:", e.target.value);
              setSelectedProfile(e.target.value);
            }}
            required
            bg={useColorModeValue("gray.300", "gray.700")}
          >
            {profiles.map((profile, index) => (
              <option key={index} value={String(profile)}>{profile}</option>
            ))}
          </Select>
        </FormControl>
        <FormControl mb="4">
          <FormLabel>Upload Signature Image</FormLabel>
          <Box
            {...getRootProps()}
            borderWidth={2}
            borderStyle="dashed"
            borderColor="gray.300"
            borderRadius="md"
            p={4}
            bg={useColorModeValue("gray.300", "gray.700")}
            textAlign="center"
            color={textColor}
          >
            <input {...getInputProps()} />
            {file ? (
              <Text>{file.name}</Text>
            ) : (
              <Text>Drag & drop an image here, or click to select one</Text>
            )}
          </Box>
        </FormControl>
        <Button type="submit" colorScheme="teal" width="full">Check Signature</Button>
      </form>
      {renderScoreScale()}
    </Box>
  );
};

export default CheckSignature;