import React, { useEffect, useState } from 'react';
import { Box, Button, Text, Input, useToast, useColorModeValue, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Stack } from '@chakra-ui/react';

const ManageProfiles = () => {
  const [profiles, setProfiles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState(null);
  const [profileToEdit, setProfileToEdit] = useState(null);
  const [newProfileName, setNewProfileName] = useState("");
  const cancelRef = React.useRef();
  const toast = useToast();

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch("/api/profiles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (Array.isArray(data)) {
            setProfiles(data);
          } else {
            toast({
              title: "Error",
              description: "Profiles data is not in expected format.",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        } else {
          toast({
            title: "Error",
            description: "Failed to load profiles.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Error",
          description: "An error occurred while fetching profiles.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchProfiles();
  }, [toast]);

  const handleDelete = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch("/api/manage_profiles", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ action: 'delete', profile_name: profileToDelete }),
      });

      if (response.ok) {
        setProfiles(profiles.filter((profile) => profile !== profileToDelete));
        toast({
          title: "Profile Deleted",
          description: `Profile ${profileToDelete} deleted successfully!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsOpen(false);
      } else {
        toast({
          title: "Error",
          description: "Failed to delete profile.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while deleting the profile.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRename = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch("/api/manage_profiles", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({ action: 'rename', profile_name: profileToEdit, new_profile_name: newProfileName }),
      });

      if (response.ok) {
        setProfiles(profiles.map((profile) => profile === profileToEdit ? newProfileName : profile));
        setProfileToEdit(null);
        setNewProfileName("");
        toast({
          title: "Profile Renamed",
          description: `Profile renamed to ${newProfileName} successfully!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Failed to rename profile.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while renaming the profile.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const boxBgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('black', 'white');
  const profileBgColor = useColorModeValue('gray.200', 'gray.600');
  const hoverBgColor = useColorModeValue('gray.300', 'gray.500');

  return (
    <Box p="4" borderRadius="lg" bg={boxBgColor} boxShadow="md">
      <Text fontSize="xl" mb="4" color={textColor}>
        Manage Profiles
      </Text>
      {profiles.length > 0 ? (
        profiles.map((profile) => (
          <Box
            key={profile}
            d="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="2"
            p="4"
            bg={profileBgColor}
            borderRadius="md"
            _hover={{ bg: hoverBgColor }}
          >
            <Text flex="1" mr="4" color={textColor}>{profileToEdit === profile ? (
              <Input
                value={newProfileName}
                onChange={(e) => setNewProfileName(e.target.value)}
                placeholder="Enter new profile name"
                size="sm"
              />
            ) : (
              profile
            )}</Text>
            <Stack direction="row" spacing="2">
              <Button
                colorScheme="blue"
                onClick={() => {
                  if (profileToEdit === profile) {
                    handleRename();
                  } else {
                    setProfileToEdit(profile);
                    setNewProfileName(profile);
                  }
                }}
                variant="outline"
              >
                {profileToEdit === profile ? "Save" : "Edit"}
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  setProfileToDelete(profile);
                  setIsOpen(true);
                }}
                variant="outline"
              >
                Delete
              </Button>
            </Stack>
          </Box>
        ))
      ) : (
        <Text color={textColor}>No profiles found.</Text>
      )}

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Profile
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete the profile "{profileToDelete}"? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ManageProfiles;
